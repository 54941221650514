import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { CardWrapper, ContainerMob } from "../../../utils/utils"

const Stopka = styled.div`
  padding-bottom: 1rem;
  text-align: center;
  background-color: ${props => props.bgColor || props.theme.color.prim1};

  a,
  p {
    color: ${({ theme }) => theme.color.white};
    opacity: 0.3;
    font-weight: 300;
    line-height: 1;
  }

  p {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  a {
    font-weight: 400;
    opacity: 0.5;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: all 0.4s ease-in-out;
    :hover {
      font-weight: 500;
      opacity: 0.7;
    }

    > div {
      margin-right: 1rem;
    }
  }

  hr {
    margin-bottom: 1rem;
    margin-top: 0;
    opacity: ${props => props.hrOpacity || 0.8};
    border: 0;
    height: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
`

const Pfr = styled(ContainerMob)`
  ${down("xs")} {
    margin-bottom: 2rem;
  }
`

const StopkaWrapper = styled(ContainerMob)`
  span {
    justify-self: center;
  }

  ${down("xs")} {
    grid-template-columns: 1fr;
    text-align: center;
    a {
      justify-content: center;
    }
    span {
      justify-self: center;
    }
  }
`

const FooterIks = props => {
  return (
    <Stopka bgColor={props.bgColor} hrOpacity={props.hrOpacity}>
      <ContainerMob>
        <hr />
      </ContainerMob>
      <StopkaWrapper>
        {/* <Pfr>
                    <StaticImage
                        src='../../../assets/images/pfr.jpg'
                        alt='Logo'
                        placeholder='blurred'
                        layout='constrained'
                        transformOptions={{ fit: 'contain' }}
                    />
                </Pfr> */}
        <span>
          <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
          <p>
            {new Date().getFullYear()} © Wszystkie prawa zastrzeżone
            <br />
            Projekt i realizacja:
          </p>

          <a href="https://iksdesign.pl/" target="blank">
            <StaticImage
              src="../../../assets/images/logo/logo_white.png"
              alt="Logo"
              placeholder="blurred"
              layout="fixed"
              height={20}
              transformOptions={{ fit: "contain" }}
            />
            IKS Design
          </a>
        </span>
      </StopkaWrapper>
    </Stopka>
  )
}
export default FooterIks
